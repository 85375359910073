// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-featured-bds-js": () => import("./../src/pages/featured/bds.js" /* webpackChunkName: "component---src-pages-featured-bds-js" */),
  "component---src-pages-featured-cpi-js": () => import("./../src/pages/featured/cpi.js" /* webpackChunkName: "component---src-pages-featured-cpi-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */)
}

